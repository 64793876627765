
import { Component, Vue } from "vue-property-decorator";
import ProgressBarComponent from "@/components/V2/ProgressBar/Index.vue";
import ActBox from "@/components/ActBox/ActBox.vue";
import { ActivityInterface } from "@/store/types/Activities/ActivitiesGeneral";
import { mapGetters } from "vuex";
import OverlayBackground from "@/components/OverlayBackground/Index.vue"
import Unauthorized from '@/components/Unauthorized/index.vue';
import router from '@/router'
@Component({
  components: {
    ProgressBarComponent,
    ActBox,
    OverlayBackground,
    Unauthorized,
  },
  computed: {
    ...mapGetters("ActivitiesModule", { metadata: "getMetaData" }),
    ...mapGetters("ActivitiesModule", ["getStatusFilter"]),
    UnauthorizedRole() {
      const role = this.$store.state.AuthModule.AuthState.role;
      return !role.some((role: any) => role.includes("Cliente") || role.includes("Implementation") || role.includes("Implementador"));
    },
  },
})
export default class ImplementationComponent extends Vue {
  private tab_active = 0;
  private metadata: any;
  private activitysByFase: ActivityInterface[] = [];
  private total_activities = 0;
  private total_activities_dones = 0;
  private percentGraffic = 0;
  private expandPhase = 1;
  private status_selected: any = [];

  private showFilter = false;
  private search = "";
  private loading = false;
  private unauthorized = false
  beforeMount() {
    const status = localStorage.getItem("activityStatus");
    const search = localStorage.getItem("activitySearch");

    if (status && JSON.parse(status).length > 0) {
      const status_array = JSON.parse(status);
      this.status_selected.push(...status_array);
    }

    if (search) {
      this.search = search;
    }
  }

  mounted() {
    const companyId = this.$route.query.companyId === undefined ? this.$store.getters['AuthModule/getCompanyIdSelected'] : this.$route.query.companyId;
    this.$store.commit("AuthModule/SET_COMPANY_ID_SELECTED", companyId);
    this.$store.commit("SteppersModule/SET_OPEN_MODAL", false);
    this.$mixpanel.track("Implementacion");
    this.getCompanyActivities();
  }

  private deleteSelectionFilters(){
    this.status_selected = [];
    this.getCompanyActivities();
  }

  private showStatus(status: string){
    if(status == "total") 
    {return false
    } else {
      return true
    }
  }

  private findActivities() {
    if (this.search.length > 2 || this.search.length === 0) {
      this.getCompanyActivities();
    }
  }

  private resetVars() {
    this.search = "";
    this.status_selected = [];
    this.$mixpanel.track("Borrar filtros");
    this.getCompanyActivities();
    localStorage.removeItem("activitySearch");
    localStorage.removeItem("activityStatus");
  }

  private elementPhase(selected: any) {
    this.$mixpanel.track("Fase: " + selected.phase);
    history.pushState({}, "activity", this.$route.path + "#" + selected.phase);

    selected.open = !selected.open;
    localStorage.setItem("phase" + selected.phase, selected.open);
  }

  private getLabel(activity: any): string {
    const totalActivities = activity.activities.length;
    // const firstActivity = activity.activities[0].name.substring(
    //   0,
    //   activity.activities[0].name.indexOf('.')
    // )
    // const lastActivity = activity.activities[totalActivities - 1].name.substring(
    //   0,
    //   activity.activities[totalActivities - 1].name.indexOf('.')
    // )

    return `Fase ${activity.phase} (${activity.done}/${totalActivities})`;
  }

  private getCompanyActivities() {
    this.total_activities = 0;
    this.total_activities_dones = 0;

    localStorage.removeItem("activityStatus");
    localStorage.removeItem("activitySearch");

    if (this.search && this.search !== "") {
      localStorage.setItem("activitySearch", this.search);
    }

    if (JSON.stringify(this.status_selected).length > 0) {
      localStorage.setItem("activityStatus", JSON.stringify(this.status_selected));
    }

    const body = {
      name: localStorage.getItem("activitySearch")
        ? localStorage.getItem("activitySearch")
        : this.search,
      status: this.status_selected,
    };

    if (this.status_selected.length != 0 || this.search != "") {
      this.$mixpanel.track("Filtros actividades", {
        Filtros: this.status_selected,
        Buscar: this.search,
      });
    }
    this.showFilter = false;

    this.loading = true;
    this.$store
      .dispatch("ActivitiesModule/getCompanyActivities", body)
      .then(({activities, status}) => {
      if (status === 200) {
        activities.forEach((element: any) => {
          if (localStorage.getItem("phase" + element.phase)) {
            const isOpenLS = localStorage.getItem("phase" + element.phase);

            if (isOpenLS === "true") {
              element.open = true;
            } else if (isOpenLS === "false") {
              element.open = false;
            }
          } else {
            element.open = true;
          }

          this.total_activities = this.total_activities + element.activities.length;

          element.done = 0;

          element.activities.forEach((data: any) => {
            if (data.status === "done") {
              element.done = element.done + 1;
            }
          });
          this.total_activities_dones = this.total_activities_dones + element.done;
        });

        this.activitysByFase = activities;
        this.unauthorized = false;
        this.percentGraffic = (this.metadata.done * 100) / this.metadata.total;
      }})
      .catch((error) => {
        if (error.response.status === 403) {
          this.unauthorized = true;
        }
        if (error.response.status === 401) {
          router.push('/')
          location.reload()
        }
      })
      .finally(() => {
        this.loading = false;
        const hash = window.location.hash;

        setTimeout(() => {
          if (hash) {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0);
      });
  }
}
