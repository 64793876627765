import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=1a9c5968&scoped=true"
import script from "./Index.vue?vue&type=script&lang=ts"
export * from "./Index.vue?vue&type=script&lang=ts"
import style0 from "./Index.vue?vue&type=style&index=0&id=1a9c5968&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a9c5968",
  null
  
)

export default component.exports