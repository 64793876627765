
import { Component, Vue, Prop, } from 'vue-property-decorator'

@Component({
  components: {},
  computed: {},
})
export default class ProgressBarComponent extends Vue {
  @Prop({ required: true, }) readonly total_activities_dones!: number
  @Prop({ required: true, }) readonly total_activities!: number
  @Prop({ required: true, }) readonly percentGraffic!: number
}
